/* .box__container {
  background: transparent linear-gradient(123deg, #4201a2 0%, #380082 100%) 0%
    0% no-repeat padding-box;
} */

.heading {
  color: black;
  -webkit-text-fill-color: white; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #5b01aa;
}

.mobile-heading {
  color: black;
  -webkit-text-fill-color: white; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #5b01aa;
}

.text-nft {
  color: #5b01aa;
  -webkit-text-fill-color: #5b01aa; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  /* -webkit-text-stroke-color: #fff; */
  text-shadow: -5px 1px 2px #ffffff;
}
