.connect {
  width: 120px;
  padding: 10px;
  color: #fff;
  font-weight: normal;
  border-radius: 20px;
  font-size: 12px;
  background-color: #ff3d7f;
}

.connected {
  width: 120px;
  padding: 10px;
  color: #fff;
  font-weight: normal;
  border-radius: 20px;
  font-size: 12px;
  background: transparent linear-gradient(98deg, #59b4c9 0%, #1ecec8 100%) 0% 0%
    no-repeat padding-box;
}

@media (max-width: 750px) {
  .connect {
    width: 120px;
    padding: 10px;
    color: #fff;
    font-weight: normal;
    border-radius: 20px;
    font-size: 12px;
    background-color: #ff3d7f;
  }

  .connected {
    width: 120px;
    padding: 10px;
    color: #fff;
    font-weight: normal;
    border-radius: 20px;
    font-size: 12px;
    background: transparent linear-gradient(98deg, #59b4c9 0%, #1ecec8 100%) 0%
      0% no-repeat padding-box;
  }
}

.mobile__nav {
  background: transparent linear-gradient(157deg, #7a00a5 0%, #0045ff 100%) 0%
    0% no-repeat padding-box;
}
