@font-face {
  font-family: "Helvetica-Bold";
  src: local("Helvetica-Bold"),
    url(./fonts/Helvetica/HelveticaBold.otf) format("truetype");
}

@font-face {
  font-family: "Helvetica-Light";
  src: local("Helvetica-Light"),
    url(./fonts/Helvetica/HelveticaLight.otf) format("truetype");
}

@font-face {
  font-family: "Helvetica-Normal";
  src: local("Helvetica-Normal"),
    url(./fonts/Helvetica/normal.otf) format("truetype");
}

@font-face {
  font-family: "Helvetica-Italic";
  src: local("Helvetica-Italic"),
    url(./fonts/Helvetica/italic.otf) format("truetype");
}

@font-face {
  font-family: "Helvetica-Roman";
  src: local("Helvetica-Roman"),
    url(./fonts/Helvetica/Roman.otf) format("truetype");
}

@font-face {
  font-family: "ActionJ";
  src: local("actionj"), url(./fonts/Actionj/actionj.ttf) format("truetype");
}

@font-face {
  font-family: "Helvetica-Md";
  src: local("Helvetica-Md"), url(./fonts/Helvetica/Md.otf) format("truetype");
}

@font-face {
  font-family: "Helvetica-Lt";
  src: local("Helvetica-Lt"), url(./fonts/Helvetica/Lt.otf) format("truetype");
}

@font-face {
  font-family: "Helvetica-Roman";
  src: local("Helvetica-Roman"),
    url(./fonts/Helvetica/Roman.otf) format("truetype");
}

@font-face {
  font-family: "Helvetica-Bd";
  src: local("Helvetica-Bd"), url(./fonts/Helvetica/Bd.otf) format("truetype");
}

@font-face {
  font-family: "Helvetica-BdLt";
  src: local("Helvetica-BdLt"),
    url(./fonts/Helvetica/Helvetica-BdLt.otf) format("truetype");
}
* {
  margin: 0;
}

body {
  margin: 0;
  font-family: Helvetica-Light, sans-serif;
}
